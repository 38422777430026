.mb-10 {
    margin-bottom: 10vh;
}

.border-yellow {
    border: 3px solid var(--color-yellow)
}

.border-right-yellow {
    border-right: 3px solid var(--color-yellow);
}

.border-left-yellow {
    border-left: 3px solid var(--color-yellow);
}

.border-bottom-yellow {
    border-bottom: 3px solid var(--color-yellow);
}

.arrow-yellow-right {
    background: url('../../../public/assets/pngs/arrows/arrow-yellow-right.png') repeat-x;
    background-size: auto 100%;
    animation: slideRight 15s linear infinite;
}

.logo {
    background: url('../../../public/assets/pngs/logo/logo.png') no-repeat;
    background-size: contain;
}

@keyframes slideRight {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 0;
    }
}

@keyframes walking-text-animation {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.walking-text {
    animation: walking-text-animation 30s linear infinite;
}

.walking-text-container {
    position: relative;
    overflow: hidden;
}


.slide-enter, .public-slide-enter {
    opacity: 0.01;
    transform: translateX(-100%);
    transition: opacity 500ms ease-out, transform 500ms ease-out;
}

.slide-enter-active, .public-slide-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms ease-out, transform 500ms ease-out;
}

.slide-exit {
    opacity: 1;
    transform: translateX(0);
}

.slide-exit-active {
    opacity: 0.01;
    transform: translateX(100%);
    transition: opacity 500ms ease-in, transform 500ms ease-in;
}

.public-slide-exit-active {
    display: none;
}