@font-face {
    font-family: 'pixer';
    src: url("../public/assets/fonts/Pixer-Regular.ttf") format("truetype");
}
@font-face {
    font-family: 'inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap') format("truetype");
}
body {
    margin: 0;
    background: radial-gradient(50% 50% at 50% 50%, #000 0%, #1E1E1E 100%) no-repeat;
    background-size: cover;
    height: 100vh;
    color: white !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
:root {
    --color-pink: #F0F;
    --color-red: #FF0000;
    --color-cyan: #0FF;
    --color-yellow: #FF0;
    --color-blue: #0500FF;
}