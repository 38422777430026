.pixer{
    font-family: 'pixer', sans-serif;
}
.inter-400{
    font-family: 'inter', sans-serif;
    font-weight: 400;
}
.inter-700{
    font-family: 'inter', sans-serif;
    font-weight: 700;
}
.text-cyan{
    color: var(--color-cyan)
}
.text-pink{
    color: var(--color-pink)
}
.text-blue{
    color: var(--color-blue)
}
.text-yellow{
    color: var(--color-yellow)
}
.button-close{
    background:transparent;
    border:0;
    font-size: 1.5em;
    color:white;
}
.button-close:hover{
    color:black;
}
.cursor-mouse{
    cursor: default;
}
.toast{
    font-size:1.1em!important;
    border-radius: 0!important;
}