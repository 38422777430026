.modal-btn-close{
    color: var(--color-blue);
    text-decoration: none;
    text-align: center;
    font-size: 2rem;
    border:none;
    background: transparent;
}
.modal-custom-content{
    background: white;
    pointer-events: auto;
}
.modal-bg-pink{
    background: var(--color-pink)!important;
}
.modal-bg-red{
    background: var(--color-red)!important;
}
.modal-bg-blue{
    background: var(--color-blue)!important;
}
.modal-bg-cyan{
    background: var(--color-cyan)!important;
}
.box-shd-cyan{
    box-shadow: 0 4px 4px 0 #11D4C3;
}
.box-shd-pink{
    box-shadow: 0 4px 4px 0 #DD91E3;
}
.box-shd-blue{
    box-shadow: 0 4px 4px 0 #0500FF;
}
.box-shd-yellow{
    box-shadow: 0 4px 4px 0 #FF0;
}
.modal{
    background: rgba(0, 0, 0, 0.6);
}