.video-background-holder {
    position: relative;
    background-color: black;
    min-height: 100%;
    height: 100vh;
    min-width: 100%;
    overflow: hidden;
}
.video-background-holder video {
    position: absolute;
    top: 50%;
    left: 50%;
    width:120%;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.video-background-content {
    position: relative;
    z-index: 2;
}