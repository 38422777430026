.anim-fade{
    animation: animationFade 1s;
}
.pixeled{
    filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='b' x='0' y='0'%3E%3CfeFlood x='4' y='4' height='2' width='2'/%3E%3CfeComposite width='10' height='10'/%3E%3CfeTile result='a'/%3E%3CfeComposite in='SourceGraphic' in2='a' operator='in'/%3E%3CfeMorphology operator='dilate' radius='5'/%3E%3C/filter%3E%3C/svg%3E#b") saturate(4);
}

@keyframes animationFade {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.bg-cyan{
    background-color: var(--color-cyan);
}
.border-cyan{
    border: 3px solid var(--color-cyan);
}
.border-blue{
    border: 3px solid var(--color-blue)
}
.border-bottom-blue{
    border-bottom: 3px solid var(--color-blue)
}
.border-top-cyan{
    border-top: 3px solid var(--color-cyan)
}
.border-left-cyan{
    border-left: 3px solid var(--color-cyan)
}
.border-bottom-cyan{
    border-bottom: 3px solid var(--color-cyan)
}
.border-right-cyan{
    border-right: 3px solid var(--color-cyan)
}
.border-top-pink{
    border-top: 3px solid var(--color-pink)
}
.border-right-pink{
    border-right: 3px solid var(--color-pink)
}
.border-left-pink{
    border-left: 3px solid var(--color-pink)
}
.border-bottom-pink{
    border-bottom: 3px solid var(--color-pink)
}
.border-top-pink{
    border-top: 3px solid var(--color-pink)
}
.border-pink{
    border: 3px solid var(--color-pink);
}
.bg-pink{
    background-color: var(--color-pink);
}
.disabled{
    cursor:not-allowed;
    opacity: .5;
}
.arrow-pink-right{
    background: url('../../../public/assets/pngs/arrows/arrow-pink-right.png') repeat-x;
    background-size: auto 100%;
    animation: slideRight 15s linear infinite;
}
.single-arrow-pink-right{
    background: url('../../../public/assets/pngs/arrows/arrow-pink-right.png') no-repeat;
    background-size: cover;
}
.single-arrow-cyan-right{
    background: url('../../../public/assets/pngs/arrows/arrow-cyan-right.png') no-repeat;
    background-size: cover;
}
.arrow-cyan-right{
    background: url('../../../public/assets/pngs/arrows/arrow-cyan-right.png') repeat-x;
    background-size: auto 100%;
    animation: slideRight 15s linear infinite;
}

@keyframes slideRight {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 0;
    }
}

.square-image{
    position: absolute;
    width:95%;
    height:95%;
    object-fit: cover;
}
.square-image-container{
    position: relative;
}
.square-image-container:after{
    content: "";
    display: block;
    padding-bottom: 100%;
}
.rotate-vertical{
    transform: rotate(-90deg);
    transform-origin: center;
    white-space: nowrap;
}
.mb-10{
    margin-bottom: 10vh;
}