.btn-cube{
    width:40px;
    height:40px;
    background: url('../../../../public/assets/pngs/buttons/cube.png') no-repeat;
}
.animate-cube{
    animation: wobbleCube 0.5s ease-in-out infinite;
    cursor: not-allowed;
}
@keyframes wobbleCube {
    0%, 100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(5deg);
    }
    75% {
        transform: rotate(-5deg);
    }
}