.btn--list-view{
    background: url('../../../../public/assets/pngs/icons/switch/list_switch.png') no-repeat;
}
.btn--block-view{
    background: url('../../../../public/assets/pngs/icons/switch/block_switch.png') no-repeat;
}
.btn--block-view-active{
    cursor: not-allowed!important;
    background: url('../../../../public/assets/pngs/icons/switch/block_switch_active.png') no-repeat;
}
.btn--list-view-active{
    cursor: not-allowed!important;
    background: url('../../../../public/assets/pngs/icons/switch/list_switch_active.png') no-repeat;
}
.btn-list-block-size{
    height:20px;
    width:20px;
    background-size: cover!important;
}