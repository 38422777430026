.mw-editsection{
    display: none;
}
a{
    color:purple;
}
.h-88{
    height:88vh;
}
.h-85{
    height: 88vh;
}