.input-form{
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--color-blue);
    border: 3px solid var(--color-yellow);
    width: 100%;
    box-shadow: 1px 3px 10px 0 #0500FF inset, 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.input-form::placeholder{
    color:var(--color-blue)
}