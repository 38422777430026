.h-40{
    height:50px;
}
.mt-20{
    margin-top:15rem!important;
}

.arrow-right{
    background: url('../../../public/assets/pngs/arrows/arrow-yellow-right.png') repeat-x;
    background-size: auto 100%;
}
.arrow-left{
    background: url('../../../public/assets/pngs/arrows/arrow-yellow-right.png') repeat-x;
    transform: scaleX(-1);
    background-size: auto 100%;
}