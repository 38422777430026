.bottom-80 {
    bottom: -90vh;
}

.btn-go {
    width: 76px;
    height: 48px;
    background: url('../../../public/assets/pngs/buttons/go.png') no-repeat;
}

.input-form--main {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--color-blue);
    width: 100%;
    border: 5px solid rgba(5, 0, 255, 0.50);
    box-shadow: 3px 3px 10px 0 #0500FF inset, 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.input-form--main::placeholder {
    color: rgba(5, 0, 255, 0.50);
}